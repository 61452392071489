import userPersonalInfo from  '../components/userPersonalInfo';
import userDetails from  '../components/userDetails';
import userAwards from  '../components/userAwards';
import userDocuments from  '../components/userDocuments';

export default
[
    {
        id: 1,
        title: 'Personal Info',
        component: userPersonalInfo,
        isActive: true,
    },
    {
        id: 2,
        title: 'Details',
        component: userDetails,
        isActive: false,
        isDisabled: true
    },
    {
        id: 3,
        title: 'Rewards & Recognition',
        component: userAwards,
        isActive: false,
        isDisabled: true
    },
    {
        id: 4,
        title: 'Document List',
        component: userDocuments,
        isActive: false,
        isDisabled: true
    }
];